<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div
      class="modal fade"
      id="modal_form_ruta_export"
      style="background-color: #00000082"
    >
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Generar listado Rutas</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal1"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  id="tab-Columnas"
                  data-toggle="tab"
                  href="#Columnas"
                  >Columnas</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="tab-Filtros"
                  data-toggle="tab"
                  href="#Filtros"
                  >Filtros
                </a>
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div class="tab-pane" id="Filtros">
                <div class="card-body">
                  <div class="form-group row">
                    <label for="nombre1" class="col-md-5">Nombre Ruta</label>
                    <input
                      type="text"
                      id="nombre1"
                      v-model="filtros.nombre"
                      class="col-md-7"
                    />
                  </div>
                  <div class="form-group row">
                    <label for="codigo1" class="col-md-5">Codigo</label>
                    <input
                      type="text"
                      id="codigo1"
                      v-model="filtros.codigo"
                      class="col-md-7"
                    />
                  </div>
                  <div class="form-group row">
                    <label for="origen_id" class="col-md-5">Origen</label>
                    <v-select
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      v-model="origen"
                      placeholder="Origenes"
                      label="nombre"
                      class="form-control form-control-sm p-0 col-md-7"
                      :options="listasForms.origenes"
                      :filterable="true"
                      @search="getSelectOrigenes()"
                    ></v-select>
                  </div>
                  <div class="form-group row">
                    <label for="destino_id" class="col-md-5">Destino</label>
                    <v-select
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      v-model="destino"
                      placeholder="Destinos"
                      label="nombre"
                      class="form-control form-control-sm p-0 col-md-7"
                      :options="listasForms.destinos"
                      :filterable="true"
                      @search="getSelectDestinos()"
                    ></v-select>
                  </div>
                  <div class="form-group row">
                    <label for="despachado_a_id" class="col-md-5"
                      >Despachado A</label
                    >
                    <select
                      class="form-control form-control-sm col-md-7"
                      v-model="filtros.despachado_a_id"
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="despachado_a_id in listasForms.despachados_a"
                        :key="despachado_a_id.numeracion"
                        :value="despachado_a_id.numeracion"
                      >
                        {{ despachado_a_id.descripcion }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group row">
                    <label for="tipo_ruta" class="col-md-5">
                      Tipo de Ruta</label
                    >
                    <select
                      class="form-control form-control-sm col-md-7"
                      v-model="filtros.tipo_ruta"
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="tipo_ruta in listasForms.tipo_rutas"
                        :key="tipo_ruta.numeracion"
                        :value="tipo_ruta.numeracion"
                      >
                        {{ tipo_ruta.descripcion }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group row">
                    <label for="horas_vigencia" class="col-md-5">
                      Horas de Vigencia</label
                    >
                    <input
                      type="number"
                      id="horas_vigencia"
                      v-model="filtros.horas_vigencia"
                      class="col-md-7"
                    />
                  </div>
                </div>
              </div>
              <div class="tab-pane fade active show" id="Columnas">
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-11">
                      <div class="form-group">
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="nombre"
                              v-model="form.nombre"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="nombre"
                              >Nombre</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="codigo"
                              v-model="form.codigo"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="codigo"
                              >Codigo</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="origen_id"
                              v-model="form.origen_id"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="origen_id"
                              >Origen</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="destino_id"
                              v-model="form.destino_id"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="destino_id"
                              >Destino</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="despachado_a_id"
                              v-model="form.despachado_a_id"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="despachado_a_id"
                              >Despachado A</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="tipo_ruta"
                              v-model="form.tipo_ruta"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="tipo_ruta"
                              >Tipo de Ruta</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="horas_vigencia1"
                              v-model="form.horas_vigencia"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="horas_vigencia1"
                              >Horas de Vigencia</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="sitios_y_p_gestion"
                              v-model="form.sitios_y_p_gestion"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="sitios_y_p_gestion"
                              >Sitios y Puntos de Gestión</label
                            >
                          </div>
                        </div>
                        <div></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer justify-content-between">
            <div>
              <button
                type="button"
                class="btn btn-sm bg-navy"
                @click="generarListadoExcel()"
                v-if="$store.getters.can('admin.rutas.export')"
              >
                Generar Listado
                <i class="fas fa-file-download"> </i>
              </button>
            </div>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import Loading from "../../../../components/Loading";
export default {
  name: "RutaExport", //llegada tambien
  components: {
    Loading,
    vSelect,
  },
  data() {
    return {
      cargando: false,
      origen: {},
      destino: {},
      form: {
        nombre: true,
        codigo: true,
        origen_id: true,
        destino_id: true,
        despachado_a_id: true,
        tipo_ruta: true,
        horas_vigencia: true,
        sitios_y_p_gestion: true,
      },
      filtros: {
        nombre: null,
        codigo: null,
        origen_id: null,
        destino_id: null,
        despachado_a_id: null,
        tipo_ruta: null,
        horas_vigencia: null,
      },

      listasForms: {
        origenes: [],
        destinos: [],
        despachados_a: [],
        tipo_rutas: [],
      },
    };
  },

  methods: {
    async getOrigenes() {
      await axios.get("/api/admin/sitios/lista").then((response) => {
        this.listasForms.origenes = response.data;
      });
    },
    getSelectOrigenes() {
      this.filtros.origen = {};
      this.filtros.origen_id = null;
      if (this.origen) {
        this.filtros.origen = this.origen;
        this.filtros.origen_id = this.origen.id;
      }
    },

    async getDestinos() {
      await axios.get("/api/admin/sitios/lista").then((response) => {
        this.listasForms.destinos = response.data;
      });
    },
    getSelectDestinos() {
      this.filtros.destino = {};
      this.filtros.destino_id = null;
      if (this.destino) {
        this.filtros.destino = this.destino;
        this.filtros.destino_id = this.destino.id;
      }
    },

    getDespachados() {
      axios.get("/api/lista/54").then((response) => {
        this.listasForms.despachados_a = response.data;
      });
    },

    getTipoRutas() {
      axios.get("/api/lista/48").then((response) => {
        this.listasForms.tipo_rutas = response.data;
      });
    },

    generarListadoExcel() {
      this.cargando = true;
      axios({
        method: "POST",
        url: "/api/admin/rutas/export",
        data: { form: this.form, filtros: this.filtros },
      })
        .then((response) => {
          let data = response.data;
          this.cargando = false;
          location.href = data.url;
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },
  },

  async mounted() {
    this.cargando = true;
    await this.getOrigenes();
    await this.getDestinos();
    await this.getDespachados();
    await this.getTipoRutas();
    this.cargando = false;
  },
};
</script>
